<template>
  <div class="medidetale">
    <div class="detale">
      <div class="fanhuibtn">
        <el-button type="primary" icon="el-icon-arrow-left" @click="returnClick"
          >返回</el-button
        >
      </div>
      <div v-html="content" class="contentS"></div>
        <div class="fileAll">
      <div
        v-for="(item, index) in fileList"
        :key="index"
        @click="ItemClick(item)"
        class="itemA"
      >
        <!-- <a href="javascript:void(0)"> {{ item.originalName }}</a> -->
        <div class="fileListImg">
                  <img
                    src="../../assets/img/pdf.png"
                    alt=""
                    v-if="
                      item.storageName.slice(-4) == '.pdf' ||
                      item.storageName.slice(-4) == '.PDF'
                    "
                  />
                  <img
                    src="../../assets/img/word.png"
                    alt=""
                    v-if="
                      item.storageName.slice(-4) == '.doc' ||
                      item.storageName.slice(-5) == '.docx'
                    "
                  />
                  <img
                    src="../../assets/img/excel.png"
                    alt=""
                    v-if="
                      item.storageName.slice(-4) == '.xls' ||
                      item.storageName.slice(-5) == '.xlsx'
                    "
                  />
                  <img
                    src="../../assets/img/video.png"
                    alt=""
                    v-if="
                      item.storageName.slice(-4) == '.mp4' ||
                      item.storageName.slice(-5) == '.mp4'
                    "
                  />
                  <a
                    :href="
                      baseUrl + 'third/third/ossGetFile/' + item.storageName
                    "
                    target="_blank"
                    >{{ item.originalName }}</a
                  >
                </div>
      </div>
    </div>
    </div>
  
  </div>
</template>

<script>
const { baseUrl } = require("@/config");
import { query } from "@/api/Medium.js";
export default {
  data() {
    return {
      baseUrl: baseUrl,
      content: null,
      fileList: [],
    };
  },
  mounted() {
    this.getlist();
  },
  methods: {
    getlist() {
      let id = this.$route.query.id;
      console.log(id);
      let config = {
        articleId: id,
      };
      query(config).then((res) => {
        if (res.code == 0) {
        console.log(res.data);
          if(res.data.fileList.length>0){
            if(res.data.fileList[0]==null){
              res.data.fileList=null
            }
          }
          this.content = res.data.content;
          this.fileList = res.data.fileList;
        }
      });
    },
    returnClick() {
      this.$router.go(-1);
    },
    fileLinkToStreamDownload(url) {
      let fileName = this.getDay();
      let reg =
        /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+).)+([A-Za-z0-9-~\/])+$/;
      if (!reg.test(url)) {
        throw new Error("传入参数不合法,不是标准的文件链接");
      } else {
        let xhr = new XMLHttpRequest();
        xhr.open("get", url, true);
        xhr.setRequestHeader("Content-Type", `application/pdf`);
        xhr.responseType = "blob";
        let that = this;
        xhr.onload = function () {
          if (this.status == 200) {
            //接受二进制文件流
            var blob = this.response;
            that.downloadExportFile(blob, fileName);
          }
        };
        xhr.send();
      }
    },
    getDay() {
      return `${this.filename}.pdf`;
    },
    downloadExportFile(blob, tagFileName) {
      let downloadElement = document.createElement("a");
      let href = blob;
      if (typeof blob == "string") {
        downloadElement.target = "_blank";
      } else {
        href = window.URL.createObjectURL(blob); //创建下载的链接
      }
      downloadElement.href = href;
      downloadElement.download = tagFileName;
      //下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      if (typeof blob != "string") {
        window.URL.revokeObjectURL(href); //释放掉blob对象
      }
    },
    ItemClick(row) {
      let newurl = this.baseUrl + "third/third/ossGetFile4/" + row.fileId;
      // if (row.storageName.slice(-4) == ".pdf") {
      //   this.fileLinkToStreamDownload(newurl);
      // } else if (row.storageName.slice(-4) == ".mp4") {
      //   window.open(newurl, "_blank");
      // } else {
      console.log(row, newurl);
      const a = document.createElement("a");
      // 给a标签的href属性值加上地址,注意:这里是绝对路径,不用加 点.
      a.href = newurl;
      // 设置下载文件文件名,这里加上.xlsx指定文件类型,pdf文件就指定.fpd即可
      // a.download = row.title;
      // 障眼法藏起来a标签
      a.style.display = "none";
      // 将a标签追加到文档对象中
      document.body.appendChild(a);
      // 模拟点击了<a>标签,会触发<a>标签的href的读取,浏览器就会自动下载了
      a.click();
      // 一次性的,用完就删除a标签
      a.remove();
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.itemA {
  margin: 10px 0;
}
.el-icon-back {
  font-size: 20px;
  margin: 20px 0;
}
.medidetale {
  width: 100%;
  height: 100%;
  // max-width: 1300px;
  margin: 0 auto;
  .detale {
    background: #fff;
    max-width: 1200px;
    margin: 0px auto;
    margin-bottom: 20px;
    padding-bottom: 40px;
  }
}
.fileAll {
  max-width: 1100px;
  margin: 20px auto 0;
  padding: 10px;
  border: 1px dashed #ccc;
  background: #F9F9F9;
  border-radius: 4px;
}
.itemC {
  width: 300px;
  margin-top: 20px;
  cursor: pointer;
  border-bottom: 1px solid #0000ee;
  color: #0000ee;
}
.D1 {
  margin-top: 5px;
  font-size: 16px;
}
.X1 {
  margin-top: 5px;
  font-size: 16px;
}
.contentS {
  video {
    margin: 0 auto;
    text-align: center;
    display: flex;
    width: 500px;
    height: 300px;
  }
}
::v-deep video {
  margin: 0 auto;
  text-align: center;
  display: flex;
  width: 1000px;
  height: 600px;
}
.fanhuibtn {
  padding-left: 20px;
  padding-top: 20px;
}
.fileListImg {
  display: flex;
  align-items: center;
  img {
    width: 45px;
    height: 45px;
    margin-right: 5px;
  }
} 
</style>